import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import stc from "string-to-color";
import { back, isRepeated } from "../../utilities";
import type { Component as CardType } from "../../typings/state.types";
import type { Mouse } from "../../typings/event.types";

export const Card: CardType = ({ inputValue, setInputValue }) => {
  const [inputValueArr, setInputValueArr] = useState([...inputValue]);
  const [hidden, setHidden] = useState(true);
  const navigate = useNavigate();
  const renderRef = useRef(true);
  const removeArrRef = useRef<number[]>([]);
  const inputValueRef = useRef(``);
  const headerRef = useRef<HTMLDivElement>(null);
  const handleClick = useCallback(
    ({ target }: Mouse) => {
      removeArrRef.current = [];
      const elemId = Number((target as HTMLButtonElement).dataset.id);
      inputValueArr.forEach((val, idx, arr) => {
        if (isRepeated(arr, val) && val === arr[elemId] && idx !== elemId) {
          removeArrRef.current.push(idx);
          return false;
        }
      });
      setInputValueArr((prevArr) => {
        return prevArr.filter((_, idx) => !removeArrRef.current.includes(idx));
      });
    },
    [inputValueArr]
  );
  useLayoutEffect(() => {
    headerRef.current?.scrollIntoView({
      behavior: `smooth`,
      block: `end`,
      inline: `nearest`,
    });
  });
  useLayoutEffect(() => {
    if (inputValueArr.length) {
      setHidden(!inputValueArr.every((val, _, arr) => !isRepeated(arr, val)));
    }
  }, [inputValueArr]);

  useEffect(() => {
    // acts like a redirect, if the user doesn't pass the check to access the protected route and manages to use the browser history UI buttons or otherwise to access the protected route that they had access to previously.
    if (inputValue.match(/^\s*$/)) {
      navigate(`/`);
      // component re-renders twice and alert() shows twice, this makes it show once
      // update: This doesn't happen in prod
      renderRef.current = !renderRef.current;
    }
    inputValueRef.current = inputValue;
    return () => {
      setInputValue(``);
      renderRef.current &&
        inputValue.match(/^\s*$/) &&
        alert(`You entered an empty value previously!`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="card">
      <div className="card__header" hidden={hidden} ref={headerRef}>
        <h2>
          Success, no duplicates!
          <br />
          original string: {inputValueRef.current}, resultant string:{" "}
          {inputValueArr.join(``)}
        </h2>
      </div>
      <h1>Card component</h1>
      {inputValueArr.map((char, idx) => (
        <h3
          key={idx}
          style={{
            backgroundColor: `${stc(char)}`,
            fontSize: `3rem`,
          }}
        >
          {char}
          <Button
            onClick={(evt: Mouse) => {
              handleClick(evt);
            }}
            data-id={idx}
            className="card__delete-btn"
          >
            Delete
          </Button>
        </h3>
      ))}
      <Button onClick={back}>Go back</Button>
    </div>
  );
};
