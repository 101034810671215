import React, { useRef } from "react";
import { Input } from "./input";
import { Button } from "./button";
import type { Component as HCType } from "../../typings/state.types";
import type { Key, Mouse, Change } from "../../typings/event.types";
import { useNavigate } from "react-router-dom";

export const HomeComponent: HCType = ({ inputValue, setInputValue }) => {
  const navigate = useNavigate();
  const spanRef = useRef<HTMLSpanElement>(null);
  const handleClick = (evt: Key | Mouse) => {
    if ((evt as Key).key === `Enter` || evt.type === `click`) {
      setInputValue((inputValue) => inputValue.trim());
      setInputValue((inputValue) => inputValue.toLowerCase());
      inputValue.match(/^\s*$/)
        ? alert(`Please enter a non-empty value`)
        : navigate(`/card`);
    }
  };
  const handleChange = ({ target: { value } }: Change) => {
    setInputValue(value);
  };
  const handleFocus = (value: string) => {
    if (spanRef.current) spanRef.current.style.opacity = value;
  };
  return (
    <div>
      <h1>Duplicate Character Remover</h1>
      <code>
        press the{" "}
        <span ref={spanRef} style={{ opacity: 0.5, transition: `.5s` }}>
          enter key
        </span>{" "}
        or the submit button for submission
      </code>
      <br />
      <br />
      <Input
        value={inputValue}
        onChange={handleChange}
        onKeyUp={handleClick}
        onFocus={() => handleFocus(`1`)}
        onBlur={() => handleFocus(`.5`)}
      />
      <Button onClick={handleClick}>Submit</Button>
      <p style={{ opacity: `.5` }}>Built with <code>React + TS </code>by<a href="https://github.com/contigen"> @contigen</a></p>
    </div>
  );
};
