import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { HomeComponent, NotFound } from "./features/ui";
import { Card } from "./features/card";

function App() {
  const [inputValue, setInputValue] = useState(``);
  const stateConfig = { inputValue, setInputValue };
  return (
    <Routes>
      <Route index element={<HomeComponent {...stateConfig} />}></Route>
      <Route path="/card" element={<Card {...stateConfig} />}></Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
